
.k-animation-container {
    z-index: 10012 !important;
}

.text-submenu-color {
    color: #476282;
}

.text-submenu-color:hover{
    color: #1b3a57;
}